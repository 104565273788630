<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Transaction Monitoring Portal</h1>
      <div class=" flex justify-end items-end">
        <button
          @click="downloadCSV"
          class="bg-blue-800 py-3 px-6 rounded-md  text-white"
        >
          Download CSV
        </button>
      </div>
      <div class="mb-5 flex mt-40">
        <button
          @click="filterToggle = !filterToggle"
          :class="
            filterToggle ? 'bg-blue-900 text-white' : 'bg-blue-50 text-blue-800'
          "
          class="
                    block
                    uppercase
                    shadow
                    ml-2
                    text-sm
                    
                    font-bold
                    
                    text-white
                    hover:bg-blue-100
                    hover:text-blue-800
                    focus:shadow-outline
                    focus:outline-none
                    py-3
                    px-3
                    rounded
                    float"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-filter"
            viewBox="0 0 16 16"
            style="display:inline;font-weight:900"
          >
            <path
              d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          Filter by
        </button>
        <button
          @click="searchByToggle = !searchByToggle"
          :class="
            searchByToggle
              ? 'bg-blue-900 text-white'
              : 'bg-blue-50 text-blue-800'
          "
          class="
                    block
                    uppercase
                    shadow
                    ml-2
                    text-sm
                    hover:text-blue-800
                    font-bold
                    bg-blue-50
                    text-white
                    hover:bg-blue-100
                    focus:shadow-outline
                    focus:outline-none
                    py-3
                    px-3
                    rounded
                    float"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
            style="display:inline;font-weight:900"
          >
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
            />
          </svg>
          Search by
        </button>
      </div>
      <div class="my-4 flex bg-gray-50 p-5" v-if="searchByToggle">
        <div class="block">
          <label class="text-sm block font-bold">Search By</label>
          <select
            class="p-3 mr-3 drop-shadow-2xl border-2 border-gray-100 bg-white py-4 px-5 rounded-md mr-2"
            v-model="statusSelect"
          >
            <option value="">Status</option>
            <option value="rrn">RRN</option>
            <option value="stan">STAN</option>
            <option value="terminalId">Terminal ID</option>
          </select>
        </div>
        <div class="block">
          <label class="text-sm block font-bold">Search Term</label>
          <input
            type="text"
            class="inline-block drop-shadow-2xl text-black bg-white py-4 px-5 rounded-md mr-2"
            name=""
            id=""
            v-model="searchByISAID"
            placeholder="Enter Search "
          />
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-blue-900 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="searchFunc"
          >
            Search
          </button>
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-red-600 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="clearDatedRecords"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-50 p-5" v-if="filterToggle">
        <div class="block">
          <label class="text-sm block font-bold">Date (From)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-4 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.startdatetime"
          />
        </div>
        <div class="block">
          <label class="text-sm block font-bold">Date (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-4 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.enddatetime"
          />
        </div>

        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-blue-900 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="fetchDatedRecords"
          >
            Filter
          </button>
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-red-600 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="clearDatedRecords"
          >
            Clear
          </button>
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-green-600 text-white py-3 px-3 mt-4 rounded-md mr-2"
            @click="downloadDatedRecords"
          >
            Download
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 500px;overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-white text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Terminal ID
              </th>
              <th
                class="bg-blue-100 text-white text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Amount
              </th>
              <th
                class="bg-blue-100 text-white text-sm whitespace-nowrap text-left px-8 py-2"
              >
                RRN
              </th>
              <th
                class="bg-blue-100 text-white text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                STAN
              </th>
              <th
                class="bg-blue-100 text-white text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Timestamp
              </th>
              <th
                class="bg-blue-100 text-white text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, i) in filterMonitoring"
              :key="i"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'ViewTransactionMonitoringPortal',
                  params: {
                    id: transaction.id,
                  },
                })
              "
            >
              <td
                class="bostartdatetimerder px-8 py-3 whitespace-nowrap text-xs"
              >
                {{ transaction.terminalid }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ transaction.amount }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ transaction.rrn }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ transaction.stan }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(transaction.created_at).format(
                    "DD MMM YYYY - h:mm:ss a"
                  )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  transaction.responsecode == "99"
                    ? "Internal Error"
                    : transaction.responsecode === "100"
                    ? "Pending"
                    : transaction.responsecode === "101"
                    ? "Failed"
                    : transaction.responsecode === "102"
                    ? "Pending"
                    : transaction.responsecode === "103"
                    ? "Reversal"
                    : transaction.responsecode === "00"
                    ? "Success"
                    : transaction.responsecode === "05"
                    ? "Do not honor"
                    : transaction.responsecode === "51"
                    ? "Not sufficient funds"
                    : transaction.responsecode === "91"
                    ? "Issuer or switch is inoperative."
                    : transaction.responsecode
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? true : false"
          class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black hover:text-white'
              : 'bg-blue-900 text-white hover:text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          v-if="filterMonitoring.length > 99"
          class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: false,
      search: "",
      accounts: null,
      filterToggle: false,
      searchByToggle: false,
      dateSelect: null,
      statusSelect: "",
      searchByISAName: "",
      searchByISAID: "",
      fromDateSelect: "",
      toDateSelect: "",
      currentPage: 1,
      dateData: null,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_MONITORING"]),
    filterMonitoring() {
      if (this.GET_MONITORING) {
        return this.GET_MONITORING.data.paginationTMList.items;
      } else {
        return [];
      }
    },
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  async created() {
    const d = new Date();
    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate() - 1))
        .toJSON()
        .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
    };
    this.fetchTransactionRecords();
  },
  methods: {
    ...mapActions([
      "FETCH_ALL_TRANSACTION_PORTAL",
      "FETCH_DATED_TRANSACTION_PORTAL",
      "SEARCH_TRANSACTION_PORTAL",
      "DOWNLOAD_TRANSACTION_PORTAL",
    ]),
    async fetchTransactionRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_ALL_TRANSACTION_PORTAL", {
          pageNumber: 1,
        });
        if (res.status == true) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = { pageNumber: currPage - 1 };
      let res = await this.$store.dispatch(
        "FETCH_ALL_TRANSACTION_PORTAL",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = { pageNumber: currPage + 1 };
      let res = await this.$store.dispatch(
        "FETCH_ALL_TRANSACTION_PORTAL",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    async fetchDatedRecords() {
      this.isLoading = true;
      let date = {
        startDate: this.dateData.startdatetime,
        endDate: this.dateData.enddatetime,
      };
      try {
        let res = await this.$store.dispatch("FETCH_DATED_TRANSACTION_PORTAL", {
          date,
        });
        if (res.status == true) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          this.$router.go();
        }
      } catch (error) {
        this.isLoading = false;
      }

      this.isLoading = false;
    },
    clearDatedRecords() {
      this.fetchTransactionRecords();
      this.search = "";
    },
    async downloadDatedRecords() {
      this.isLoading = true;
      let date = {
        startDate: this.dateData.startdatetime,
        endDate: this.dateData.enddatetime,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_DATED_TRANSACTION_PORTAL_CSV",
          {
            date,
          }
        );
        if (res.status == true) {
          this.isLoading = false;
          window.open(res.data.downloadLink, "_blank");
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          this.$router.go();
        }
      } catch (error) {
        this.isLoading = false;
      }

      this.isLoading = false;
    },
    async filterFunc() {},
    async searchFunc() {
      this.isLoading = true;
      let data = {
        status: this.statusSelect,
        search: this.searchByISAID,
      };
      try {
        let res = await this.$store.dispatch("SEARCH_TRANSACTION_PORTAL", data);
        if (res.status == true) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async downloadCSV() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("DOWNLOAD_TRANSACTION_PORTAL");
        if (res.status == true) {
          // let downloadLink = res.data.downloadLink.replace(".txt", ".csv");
          window.open(res.data.downloadLink, "_blank");
          // console.log(res.data.downloadLink);
          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: #000;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
